body {
  font-family: 'Hero-R';
}
/*common*/
.fs-success {
  background-color: #017301;
  color: #fff;
}
.fs-danger {
  background-color: #dd4c39;
  color: #fff;
}
.fs-primary {
  background-color: #1769ff;
  color: #fff;
}
.black-btn {
  border: none;
  background: #000;
  color: #fff;
  display: block;
  width: 100%;
  height: 45px;
  border-radius: 5px;
}
.small-screen-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  text-align: center;
  overflow: auto;
}
.separator {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1.5px solid #d3d3d3;
  opacity: 1;
  display: block;
}
.side-line {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid #000;
  opacity: 1;
  display: block;
}
.list-hint {
  display: block;
  font-size: 12px;
}
.hint-section {
  background-color: #f7c4c0;
  display: block;
  padding: 15px;
  border-radius: 5px;
  text-transform: uppercase;
}
.hint-section .hint-title {
  font-size: 20px;
  font-weight: 600;
}
.hint-section .hint-text {
  font-size: 14px;
  margin: 0;
}
.empty-section {
  margin-top: 2rem;
}
.empty-section img {
  width: 150px;
  margin: auto;
  display: block;
  margin-bottom:1rem;
}
.empty-section p {
  text-align: center;
  margin-top: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  color: grey;
  font-style: italic;
}
.pagination {
  margin-top: 2rem;
  justify-content: center;
  gap: 10px;
}
.pagination button.page-btn {
  border-radius: 5px;
  background: #fff;
  padding: 10px 15px;
}
.pagination button.page-btn.active {
  background: #880d4e;
  color: #fff;
  border-color: #880d4e;
}
.cropper-bg {
  background: #000;
  background-image: none !important;
}

.loading-img .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
  }

.loading-img box {
  height: 104px;
  width: 100px;
}

.loading-img .shine-box {
  display: flex;
  flex-direction: row;
}

.loading-img .shine-box div{
  margin-left: 25px;
}

.loading-img div {
  display: inline-flex;
  flex-direction: column; 
  margin-top: 15px;
  vertical-align: top; 
}

.loading-img lines {
  height: 10px;
  margin-top: 10px;
  width: 200px; 
}

.loading-img photo {
  display: block!important;
  width: 325px; 
  height: 100px; 
  margin-top: 15px;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

div:where(.swal2-container).swal2-backdrop-show, div:where(.swal2-container).swal2-noanimation {
  backdrop-filter: blur(15px);
}
.gm-style div {
  cursor: move!important;
}
/* From Uiverse.io by omar49511 */ 
.container-btn-file {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #880d4e;
  color: #fff;
  border-style: none;
  padding: 1em 2em;
  border-radius: 0.5em;
  overflow: hidden;
  z-index: 1;
  box-shadow: 4px 8px 10px -3px rgba(0, 0, 0, 0.356);
  transition: all 250ms;
}
.container-btn-file input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.container-btn-file > svg {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1em;
}
.container-btn-file::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  border-radius: 0.5em;
  color: #000;
  background-color: #212121;
  z-index: -1;
  transition: all 350ms;
}
.container-btn-file:hover::before {
  width: 100%;
}
.loading-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  background: rgb(255 255 255 / 100%);
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.loader div {
  width: 8%;
  height: 24%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  animation: fade458 1s linear infinite;
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}


/*.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  --color: hsl(0, 0%, 87%);
  --animation: 2s ease-in-out infinite;
  position: fixed;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1099;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}

.loader .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20px;
  height: 20px;
  border: solid 2px var(--color);
  border-radius: 50%;
  margin: 0 10px;
  background-color: transparent;
  animation: circle-keys var(--animation);
}

.loader .circle .dot {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--color);
  animation: dot-keys var(--animation);
}

.loader .circle .outline {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: outline-keys var(--animation);
}

.circle:nth-child(2) {
  animation-delay: 0.3s;
}

.circle:nth-child(3) {
  animation-delay: 0.6s;
}

.circle:nth-child(4) {
  animation-delay: 0.9s;
}

.circle:nth-child(5) {
  animation-delay: 1.2s;
}

.circle:nth-child(2) .dot {
  animation-delay: 0.3s;
}

.circle:nth-child(3) .dot {
  animation-delay: 0.6s;
}

.circle:nth-child(4) .dot {
  animation-delay: 0.9s;
}

.circle:nth-child(5) .dot {
  animation-delay: 1.2s;
}

.circle:nth-child(1) .outline {
  animation-delay: 0.9s;
}

.circle:nth-child(2) .outline {
  animation-delay: 1.2s;
}

.circle:nth-child(3) .outline {
  animation-delay: 1.5s;
}

.circle:nth-child(4) .outline {
  animation-delay: 1.8s;
}

.circle:nth-child(5) .outline {
  animation-delay: 2.1s;
}

@keyframes circle-keys {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes dot-keys {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes outline-keys {
  0% {
    transform: scale(0);
    outline: solid 20px var(--color);
    outline-offset: 0;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    outline: solid 0 transparent;
    outline-offset: 20px;
    opacity: 0;
  }
}
*/

div:where(.swal2-container) {
  z-index: 9999!important;
}

/*Modal css*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1099;
}
.modal-header {
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}
.modal-content {
  background: #ffffff!important;
  padding: 20px;
  border-radius: 8px!important;
  max-width: 600px;
}
.close-button {
  background: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  color: #880d4e;
  font-weight: 600;
  font-family: 'Hero-R';
}
.business-lists {
  margin-bottom: 2rem;
}
.mbl-box {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mbl-box > * {
  margin: 6px;
}
.mbl-input:checked + .mbl-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #2260ff;
}
.mbl-input:checked + .mbl-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #2260ff;
  border-color: #2260ff;
}
.mbl-input:checked + .mbl-tile .mbl-icon img {
  fill: #2260ff;
}
.mbl-input:checked + .mbl-tile .mbl-bname {
  color: #2260ff;
}
.mbl-input:focus + .mbl-tile {
  border-color: #2260ff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
}
.mbl-input:focus + .mbl-tile:before {
  transform: scale(1);
  opacity: 1;
}
.mbl-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  border-radius: 0.5rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}
.mbl-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #b5bfd9;
  background-color: #fff;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}
.mbl-tile:hover {
  border-color: #2260ff;
}
.mbl-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}
.mbl-icon img {
  width: 200px;
  height: 200px;
  border:2px solid #d3d3d3;
}
.mbl-bname {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
}
.mbl-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


/**/
.box{
    border:12px solid #461454;
    background:#461454;
	  position:relative;
    z-index: -1;
}

.image-box{
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

.image-box img{
  padding:4px;
}

@media (min-width: 992px){
    .col-lg-3 {
        max-width: 20%!important;
        padding-left: 0!important;
        padding-right: 0!important;
    }
}

.loginBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 420px;
    padding: 70px 40px;
    border-radius: 5px;
    box-sizing: border-box;
    z-index: 2;
    background:hsla(0,0%,100%,.6) border-box;
    box-shadow:0 0 0 1px hsla(0,0%,100%,.6) inset,
                  0 .5em 1em rgba(0, 0, 0, 0.6);
}

.loginFormBox{
    width: 100%;
    height: 100%;
}
  
.user {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: calc(-100px/2);
    left: calc(50% - 50px);
  }
  
.loginBox .login-title {
    font-family: 'Hero-M';
    margin: 0;
    padding: 0 0 26px;
    text-align: center;
}
  
.loginBox p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #fff;
}

.loginBox input {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}

.loginBox .login-btn {
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  background: #870d4e;
  border: 1px solid #870d4e;
}

.loginBox input[type="email"],
.loginBox input[type="password"] {
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  height: 40px;
  color: #fff;
  font-size: 16px;
}

::placeholder
{
  color: rgba(255, 255, 255, 0.5);
}

.loginBox input[type="submit"] {
  border: none;
  outline: none;
  height: 40px;
  color: #eee;
  font-size: 16px;
  background-color: #FF8C00;
  cursor: pointer;
  border-radius: 20px;
  margin: 12px 0 18px;
}

.loginBox input[type="submit"]:hover {
  background-color: #ff9719;
  color: #fff;
}

.loginBox a {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

.oauth-app {
  color: #424242;
  height: 100vh;
  width: 100vw;
}

@media only screen and (min-width: 992px) {
  .oauth-app {
      display: flex;
  }
}

@media only screen and (min-width: 992px) {
  .oauth-app .art-wrapper {
      width: calc(100% - 480px);
  }
}

.oauth-app .art-wrapper {
  background-color: #f8fafb;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.oauth-app .art-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(75%);
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
}
.oauth-app .content-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 40px;
  background-color: #fff;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.oauth-app .content-wrapper .login-logo {
  width:200px;
  text-align: center;
  margin-bottom: 2rem;
}

.form-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.oauth-app input {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
}

.oauth-app .login-btn {
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  background: #870d4e;
  border: 1px solid #870d4e;
}

.oauth-app input[type="email"],
.oauth-app input[type="password"] {
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  height: 40px;
  color: #fff;
  font-size: 16px;
}

.oauth-app input[type="submit"] {
  border: none;
  outline: none;
  height: 40px;
  color: #eee;
  font-size: 16px;
  background-color: #FF8C00;
  cursor: pointer;
  border-radius: 20px;
  margin: 12px 0 18px;
}

.oauth-app input[type="submit"]:hover {
  background-color: #ff9719;
  color: #fff;
}

.oauth-app a {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}


.form-holder .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 60px;
}

.form-content .form-items {
  border: 3px solid #fff;
  padding: 40px;
  display: inline-block;
  width: 100%;
  min-width: 700px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.registration-form {
  background: linear-gradient(to bottom, #880d4e 0%, #880d4e 16%, #bfc3fc 0%, #bfc3fc 100%);
  border-radius: 5px;
}

.myp-form .form-items {
  border:none;
}

.form-content h3 {
  color: #fff;
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-content .register-title {
  color: #fff;
  text-align: left;
  margin-bottom: 1rem;
}

.form-content h3.form-title {
  margin-bottom: 30px;
}

.form-content p {
  color: #fff;
  text-align: left;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 30px;
}

.form-content label, .was-validated .form-check-input:invalid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
  color: #fff;
}

.form-content input[type=text], .form-content input[type=email], .form-content select {
  width: 100%;
  padding: 9px 0.75rem;
  text-align: left;
  border: 2px solid #d3d3d3;
  outline: 0;
  border-radius: 4px;
  background-color: #fff;
  font-size: 15px;
  color: #8D8D8D;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 16px;
}

.myp-form input[type=text], .myp-form input[type=email], .myp-form select {
  border:2px solid #d3d3d3;
}

.cp-btn{
   background-color: #870d4e;
   outline: none;
   border: 0px;
   box-shadow: none;
   border-radius: 5px;
   color: #fff;
   width: 100%;
   text-align: center;
   padding: 15px;
   text-decoration: none;
   display: block;
   font-weight: 400;
   margin-top: 10px;
}

.form-content textarea {
  position: static !important;
  width: 100%;
  padding: 8px 20px;
  border-radius: 6px;
  text-align: left;
  background-color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: 300;
  color: #8D8D8D;
  outline: none;
  resize: none;
  height: 120px;
  -webkit-transition: none;
  transition: none;
  margin-bottom: 14px;
}

.form-content textarea:hover, .form-content textarea:focus {
  border: 0;
  background-color: #ebeff8;
  color: #8D8D8D;
}

.mv-up{
  margin-top: -9px !important;
  margin-bottom: 8px !important;
}

.invalid-feedback{
  color: #ff606e;
}

.valid-feedback{
 color: #2acc80;
}

.location-icon {
  position: absolute;
  right: 0px;
  top: 15px;
  border: none;
  background: transparent;
}

.location-icon img {
  width:30px;
}
.map-address-box {
  background: #bfc3fc;
  padding: 15px 10px;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

/*Sidebar*/
.side-icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

/*Home Page*/
.profile-section {
  margin-top: 3rem;
}
.profile-box {
  display: flex;
  padding-bottom: 40px;
  border-bottom: 1.5px solid #d3d3d3;
}
.profile-footer {
  padding-top: 40px;
}
.profile-footer .pf-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cfcfcf;
  color: #000;
  height: 45px;
  text-transform: uppercase;
  border-radius: 5px;
  text-decoration: none;
}
.profile-footer .pf-btn:hover{
  background: #cfcfcf;
  color: #000; 
}
.profile-image-box {
  padding-right: 80px;
  border-right: 1.5px solid #A9A9A9;
}
.profile-info-box {
  padding-left: 80px;
}
.profile-image-box .profile-img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 0.5px solid #000;
}
.profile-info-box .business-name{
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.popup-images {
  padding-bottom: 2rem;
}
.popup-images img {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.popup-images video {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.lc-count-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 13px;
  color: #880d4e;
  cursor: pointer;
}
.lcs-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  margin-bottom: 15px;
  border-top: 1px solid #d3d3d3;
}
.lcs-box img {
  width:35px;
  cursor: pointer;
}
.lcvsb {
  position: absolute;
  bottom: 30px;
  right: 20px;
  border: none;
  display: block;
  text-align: center;
  color: #fff;
}
.lcvsb img {
  margin-bottom: 5px;
}
.piphs {
  display: flex;
  align-items: center;
}
.piphs img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.video-profile-section {
  position: absolute;
  width: 80%;
  left: 20px;
  bottom: 40px;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.6);
}
.video-profile-section img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
  margin-bottom: 0;
}
.vstxtbox {
  display: flex;
  align-items: center;
}
.video-profile-txt {
  text-transform: uppercase;
}
.video-profile-txt span{
  display: block;
  color: #fff;
  font-size: 12px;
}
.subscribe-now-box {
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.subscribe-now-link {
  display: inline-block;
  width: 100%;
  height: 45px;
  background: #004b00;
  color: #fff;
  text-decoration: none;
  text-align: center;
  align-content: center;
  border-radius: 5px;
  text-transform: uppercase;
}
.subscribe-now-link:hover {
  color: #fff;
}

/*search outlet*/
.custom-model-main {
  text-align: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; 
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
  overflow-x: hidden;
  overflow-y: auto;
  background:#fff;
}
.custom-model-inner {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, 
  -webkit-transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 600px;
  margin: 30px auto;
}
.custom-model-inner input {
  color: #000;
  height: 50px;
  margin-bottom: 2rem;
  padding-left:30px!important
}
.custom-model-wrap {
  display: block;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
  text-align: left;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.out-search-box {
  position:relative;
}
.out-search-box .sc-btn{
  position:absolute;
  top: 4px;
  right: 15px;
  font-size: 30px;
  color: #000;
  font-weight: 800;
  cursor: pointer;
}
.outlet-list-box {
  list-style: none;
  padding: 0;
}
.outlet-list-data {
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 30px 20px;
  text-align: center;
}
.outlet-list-data h4 {
  color:#000;
  font-weight: 800;
  text-transform: uppercase;
}
.outlet-list-data p {
  color:#000;
  text-align: center;
  font-size: 12px;
}
.outlet-list-data .claim-btn {
  color: #fff;
  text-align: center;
  border: none;
  background: #000;
  text-transform: uppercase;
  font-size: 16px;
  padding: 6px;
  width: 100%;
  border-radius: 8px;
  height: 50px;
}
.notl-box {
  position: fixed;
  width: inherit;
  bottom: 10px;
  background: #fff;
  padding: 10px 0;
}
.notl-btn {
  color: #fff;
  text-align: center;
  border: none;
  background: #000;
  text-transform: uppercase;
  font-size: 16px;
  padding: 6px;
  width: 100%;
  border-radius: 8px;
  height: 50px;
}

/*register page css*/
.os-section {
  border-radius: 15px;
  padding: 15px 0;
  margin-bottom: 1rem;
  display:flex;
}
.myp-form .os-section {
  padding:15px 0;
}
.os-box {
  width:80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.os-box .search-outlet-btn{
  box-shadow: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: 800;
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  padding: 20px;
  color: #000;
  background: #fff;
  font-size: 20px;
  width: 100%;
}
.os-section .logo-box {
  width:20%;
  margin-left:10px;
  text-align: center;
}
.os-section .logo-box img{
  height: 65px;
  text-align: end;
  width: 65px;
  display: block;
  margin: auto;
  margin-bottom: 5px;
  border:2px solid #d3d3d3;
}
.os-section .logo-box .logo-title {
  font-size: 10px;
  display: block;
  text-align: center;
  color:#000;
  cursor: pointer;
  text-transform: uppercase;
}
.form-hint {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
}
.registration-form .form-hint {
  color:#000;
}
.myp-form .form-hint {
  color: #000;
}
.tc-box, .tc-box label {
  font-size: 14px;
  color:#000;
  text-transform: uppercase;
}
.tc-box label a{
  font-size: 14px;
  color:#000;
  text-decoration: none;
}

/*add business listing css*/
.form-title {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 1rem;
}
.form-section label {
  text-transform: uppercase;
  font-size: 12px;
  position: relative;
}
.form-section legend {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}
.form-section .form-control {
  border:2px solid #d3d3d3;
}
.form-section .form-check-input[type=radio], .form-check-input[type=checkbox] {
  border: 2px solid #000;
}
.form-check-input[type=checkbox] {
  border-radius: 2px !important;
}
.form-check .form-check-input, .form-check .form-check-label{
  cursor: pointer;
}
.doc-box {
  text-align: center;
}
.doc-box .bd-label {
  border: 1px solid #86124f;
  padding: 10px 15px;
  cursor: pointer;
}
.doc-box .img-pdf{
  width:100px;
  display: block;
  margin: auto;
}
.doc-box .bps-icon{
  width:40px;
  display: block;
  margin: auto;
}
.char-lists .form-check {
  display:inline-block;
  margin-right:10px;
}
.checkPop .select-btn{
  border: none;
  background-color: #b6aaaa;
  height: 50px;
  width: 100%;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border-radius: 5px;
}
.sdcb {
  margin-top: 1rem;
}
.sdcb .sdlb {
  color: #fff;
  background-color: #870d4e;
  padding: 5px 15px;
  border-radius: 30px;
  font-style: italic;
  font-weight: 100;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
}
.sdcb .sdlb .rdflb {
  margin-left: 10px;
  font-style: normal;
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  color: #870d4e;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  font-weight: 800;
  cursor: pointer;
}
.api-data-list {
  margin-bottom: 100px;
}
.adlb {
  display: flex;
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid #d3d3d3;
}
.adlb input{
  margin:0;
  margin-right:10px;
  height: 30px;
  cursor: pointer;
}
.adlb label{
  cursor: pointer;
  font-size: 16px;
}
.sctcb-box {
  position: fixed;
  bottom: -58px;
  max-width: 610px;
  width: 100%;
  margin: auto;
  left: 49.4%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.done-btn {
  border-radius: 5px;
  background-color: #870d4e;
  color: #fff;
  border: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  text-transform: uppercase;
}

/*Pricing Css*/
.section__header {
  margin-bottom: 4.5rem;
  text-align: center;
  line-height: 1.2;
}

.section__title {
  margin: 0 0 1rem;
  font-size: 2rem;
  color: #000;
}

.row--wrap {
  flex-wrap: wrap;
  row-gap: 2rem;
  gap: 2rem;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  fill: #1769ff;
}

.icon--white {
  fill: #fff;
}

.pricing-button {
  width: 100%;
  border: 0.125rem solid transparent;
  border-radius: 5px;
  padding: 0.8125rem;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  background-color: #880d4e;
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
}

.pricing-button:hover {
  color: #fff;
  background-color: #ea4c89;
}

.button--light {
  background-color: #fff;
}

.button--light:hover {
  border: 0.125rem solid #fff;
  background-color: transparent;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  width: 100%!important;
  border-radius: 5px;
  padding: 2rem 3rem!important;
  color: #656c7c;
  background-color: #fff;
}

.pricing-card--primary {
  color: #fff;
  background-color: #1769ff;
}

.pricing-card--outline {
  border: 2px solid #880d4e;
}

.pricing-card--shadow {
  box-shadow: 0 0.875rem 1.875rem rgba(204, 204, 204, 0.32);
}

.pricing-card--best {
  order: -1;
}

@media only screen and (min-width: 50.5em) {
  .pricing-card--best {
    order: 0;
  }
}

.pricing-card__head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pricing-card__tier {
  margin: 0;
  margin-right: auto;
  font-size: 1.5rem;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
}

.pricing-card__tag {
  border-radius: 9999px;
  padding: 0.625rem 1.125rem;
  font-weight: 600;
  color: #fff;
  background-color: #fdb72e;
}

.pricing-card__features {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
  color: #000;
}

.pricing-card__feature {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #880d4e;
}

.pricing-card__feature:last-child {
  border-bottom: 0;
}

.pricing-card__feature--disabled {
  color: #b1b8c9;
  fill: #b1b8c9;
}

.subs-icon {
  width: 40px;
}

.pricing-card__feature i {
  margin-top:6px;
  margin-left: auto;
}

.pricing-feature_points {
  width: 100%;
}

.pricing-fp-box {
  display:flex;
  justify-content: space-between;
  gap: 1rem;
}

.pricing-feature_points p{
  margin-bottom: -6px;
  text-transform: uppercase;
}

.pricing-feature_points .pricing-points_desc{
  display:block;
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.pricing-feature_points span {
  display:block;
  font-weight: 400;
  font-size: 8px;
  text-transform: uppercase;
  padding-top: 5px;
}

.pricing-feature_points .allowed-unit_desc {
  border-top: 1px solid #880d4e;
}

.pricing-card__bullet {
  width: 1.5rem;
  height: 1.5rem;
  fill: inherit;
}

.pricing-card__action {
  margin-top: auto;
}

.pricing-line {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 2px solid #880d4e;
  opacity: 1;
  display: block;
}
.slick-slide > div {
  margin: 0 20px; 
}
.slick-list {
  margin: 0 -20px; 
}
.slick-dots {
  top: -40px;
  left: 0;
  bottom: unset!important;
}
.slick-dots li button:before {
  font-size: 10px!important;
}
.slick-dots li button:hover:before,
.slick-dots li.slick-active button:before {
  color: #880d4e!important;
}
.coupon-drawer-footer {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: 1.5rem;
}
.coupon-drawer-footer .btn {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  text-transform: uppercase;
}

/*My Subscription*/
.ms-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.ms-box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #d3d3d3;
}
.ms-box-header h4{
  font-weight: 800;
  margin:0;
  padding-bottom: 0;
  text-transform: uppercase;
}
.ms-body-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.ms-price-title {
  color: #000;
  font-weight: 600;
  margin-bottom:1rem;
  text-transform: uppercase;
}
.ms-box-footer {
  margin-top:2rem;
}
.ms-body-flex-item {
  display:flex;
  gap:5px;
}
.ms-body-flex-item p{
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 600;
}
.ms-purchase-status {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 5px;
}
.ms-features {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 500;
  color: #000;
}
.ms-feature {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.ms-feature:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.ms-feature-point .subs-icon {
  width: 80px;
  margin-bottom: 1rem;
}
.ms-feature-point {
  font-family: 'Hero-R';
  display: block;
  text-align: center;
  height: 220px;
  width: 100%;
  border-radius: 5px;
  padding: 25px 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.ms-feature-point .mspfts {
  border-top:1px solid;
  padding-top: 1rem;
}
.ms-feature-point p {
  font-size: 10px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.ms-feature-point span {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  padding-top: 6px;
}
.active{
  background: #76bb22;
  color: #fff;
}
.inactive{
  background: #d50100;
  color: #fff;
}
.osp {
  text-decoration: line-through;
  margin-right: 6px;
}

/*Manage Listing*/
.ml-box {
  background-color: #e9e7e8;
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: 2rem;
}
.ml-header {
  display: flex;
}
.ml-image {
  width: 50px;
  margin-right: 10px;
}
.ml-header h4 {
  font-weight: 800;
  padding: 0;
  margin: 0;
}
.ml-city {
  color: #c3bfc0;
  margin: 0;
}
.ml-pvo {
  color: #000000;
}
.ml-edit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #007401;
  color: #fff;
  width: 175px;
  height: 30px;
  margin-bottom: 15px;
  text-decoration: none;
  border-radius: 5px;
}
.ml-edit-btn:hover {
  color:#fff;
}
.ml-footer {
  display: flex;
  justify-content: space-between;
}
.ml-footer p {
  margin: 0;
  font-size: 12px;
}

/*home listing*/
.bl-line {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid #d3d3d3;
  opacity: 1;
  display: block;
}
.bldv-img .blimgbox{
  position: relative;
  display: flex;
  align-items: center;
}
.bldv-img .bl-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.bldv-img .bl-shield {
  position: absolute;
  bottom: 29px;
  right: 10px;
  width: 30px;
}
.bl-rating {
  margin-left:20%;
  font-size: 25px;
}
.bl-cuisines span {
  padding:0 5px;
  border-right:1px solid #000;
}
.bl-cuisines span:first-child {
  padding-left:0;
}
.bl-cuisines span:last-child {
  border-right: none;
}
.lcbs .lc-btn {
  border:none;
  width: 100%;
  background: #880d4e;
  color: #fff;
  height: 45px;
  border-radius: 5px;
  text-transform: uppercase;
}
.frfbox {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.frfbox div{
  text-align: center;
  cursor: pointer;
}
.frfbox a {
  text-align: center;
  cursor: pointer;
  text-decoration:none;
}
.frfbox .frf-title {
  font-size: 16px;
  margin-bottom: 0;
}
.frfbox .frf-number {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
.frfbox .mto-title {
  color:#656c7c;
  font-size: 16px;
  margin-bottom: 0;
}
.frfbox .mto-img {
  width: 50px;
}
.lpbs .lp-title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 1rem;
}
.lpis {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.lpib {
  position: relative;
  width:100%;
}
.photo_grid_view {
  width:100%;
}
.lp-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
}
.lpib-action-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  top: 0;
  right: 0;
  width: 105px;
  font-size: 14px;
  color: #fff;
  border-radius: 0 10px 0 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
.lpib-action-overlay button {
  border: none;
  background: transparent;
  color: #fff;
}
.lpib-action-overlay a {
  color: #fff;
  padding: 1px 6px;
}
.lpib-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 10px;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  color: #fff;
  border-radius: 0 0 10px 10px;
  background: rgba(0, 0, 0, 0.6);
}
.lpib-overlay-img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}
.lpib-overlay-text p {
  margin-bottom: 0;
  font-size: 10px;
  line-height: 1.4;
}
.lcdbox {
  display:flex;
  align-items: center;
  gap:10px
}
.lcdubox {
  border:2px solid #000;
}
.lcu-img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  padding: 20px;
}
.lcdutxt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #BEBFF7;
  font-size: 20px;
  padding: 10px;
  border-top:2px solid #000;
}
.lcp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f0f0f0;
  padding: 10px 20px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  border-radius: 5px;
}
.lcp-header h4 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 800;
}
.lcp-header p {
  margin-bottom: 0;
  font-style: italic;
}
.lculb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:10px;
  border-bottom: 1px solid #d3d3d3;
}
.lcirts {
  display: flex;
  align-items: center;
}
.lcuib img{
  margin-right: 10px;
}
.lcuib .lcllu-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.lcucb {
  line-height: 1.2;
}
.lcucb p {
  margin-bottom: 0;
}
.lcucb .lcuc-time {
  font-size: 12px;
  color: #880d4e;
}
.star.filled {
  color: #880d4e;
}
.star.empty {
  color: #d3d3d3;
}
.lcrb .lcr-txt {
  border: none;
  background: #880d4e;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
}
.lcudpds {
  border-bottom: 2px solid #d3d3d3;
  padding-bottom: 20px;
}
.lcudirb {
  display: flex;
  align-items: center;
}
.lcudirb img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 50px;
}
.lcudrb {
  text-align: center;
}
.lcud_name {
  font-weight: 600;
}
.lcudrb p {
  font-size: 20px;
  margin-bottom: 0;
}
.rate-btn {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  min-width: 150px;
  text-transform: uppercase;
}
.lcudun {
  margin-top: 10px;
}
.lcudun p{
  margin-bottom: 0;
}
.cpo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #880d4e;
  color: #fff;
  padding: 10px;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  text-transform: uppercase;
}
.cpo-header h4 {
  margin: 0;
  font-size: 18px;
}
.cpolb {
  margin-bottom: 10px;
}
.cpolmb {
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.cpolmb img {
  width: 100%;
  margin: auto;
  display: block;
}
.cpolmb .lcuc-title {
  margin: 0;
  background: #320070;
  color: #fff;
  height: 45px;
  border-radius: 5px;
  border: none;
  display: none;
  width: 100%;
}
.cpo-txt {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.cpotxtbox {
  position: absolute;
  bottom: 0;
  color: #fff;
}
.cpotxtbox .cpo-txt {
  font-size: 12px;
  font-family: 'Hero-R';
}
.cpods {
  margin-top: 1rem;
}
.cpods img {
  width:100%;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}
.cpods p {
  color: #320070;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
}
.cpods-details .cpao-btn {
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  background: #000;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  text-transform: uppercase;
}

.cpa-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 3rem;
  padding:0;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 0rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #212121), linear-gradient(137.48deg, #ffdb3b 10%, #ff9b17d7 45%, #f9ff41 67%, #feb200d7 87%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 10px;
  text-transform: uppercase;
}

.cpa-btn #container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
}

.cpa-btn strong {
  font-family: 'Hero-R';
  z-index: 2;
  font-size: 16px;
  letter-spacing: 3px;
  color: #FFFFFF;
  text-shadow: 0 0 4px rgb(0, 0, 0);
}

.cpa-btn #glow {
  position: absolute;
  display: flex;
  width: 100%;
}

.cpa-btn .circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.cpa-btn .circle:nth-of-type(1) {
  background: rgba(0, 0, 1860 0.936);
}

.cpa-btn .circle:nth-of-type(2) {
  background: rgba(0, 0, 1860 0.936);
}

.cpa-btn:hover #container-stars {
  z-index: 1;
  background-color: #212121;
}

.cpa-btn:active {
  border: double 4px #FE53BB;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.cpa-btn:active .circle {
  background: #FE53BB;
}

.cpa-btn #stars {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

.cpa-btn #stars::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

.cpa-btn #stars::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

.cpa-btn #stars::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

.cpa-btn #stars::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.cpods-details .cpotct {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  margin-bottom: 1rem;
}
.cpods-terms {
  text-transform: uppercase;
  font-size: 12px;
}
.cp-background {
  background-image: url("https://partner.fyresearch.com/assets/img/background/cp_background.webp")!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
}
.lc-background {
  background-image: url("https://partner.fyresearch.com/assets/img/background/tlc_banner.webp")!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
}
.tcp-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}
.tcp-header .popup-close {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.tab-buttons .tb-btn {
  border: none;
  background: transparent;
  width: 50%;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.tab-buttons .tb-btn.tb-active {
  border-bottom: 2px solid #880d4e;
}
.follow-followes-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid #d3d3d3;
  cursor: pointer;
}
.follow-followes-list .ffl-img {
  position: relative;
  height: 60px;
}
.follow-followes-list .ffl-img .ffl-sender-img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: cover;
}
.follow-followes-list .ffl-img .ffl-category-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 25px;
  height: 25px;
  margin: 0;
}
.follow-followes-list .fflb img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  object-fit: cover;
}
.follow-followes-list .fflb {
  display: flex;
  justify-content: space-between;
}
.ffl-txt .noti-title{
  font-size: 18px;
  margin: 0;
}
.ffl-txt .noti-desc{
  font-size: 16px;
  margin: 0;
}
.ffl-txt p{
  font-size: 14px;
  margin: 0;
}
.req-btn {
  display: block;
  width: 100px;
  border: none;
  height: 25px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;
}
.accept {
  background-color: #326c27;
  color: #ffffff;
}
.reject {
  background-color: #b54133;
  color: #ffffff;
}

/*Comment popup*/
.comments-list {
  min-height: 70vh;
  padding: 20px;
  padding-right: 0;
}
.comment-item {
  margin-bottom: 1rem;
}
.comment-form {
  position: relative;
}
.empty-comment {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}
.comment-detail-box {
  display:flex;
}
.comment-detail-box .comment-person-profile {
  width:40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.comment-ta {
  width:100%;
}
.comment-text {
  width:100%;
  padding: 10px;
  background: #f4f4f4;
  border-radius: 4px;
}
.comment-text p {
  margin: 0;
  color:#000;
}
.comment-action {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding:5px 10px;
  text-transform: uppercase;
}
.comment-action p {
  margin: 0;
  font-size: 12px;
}
.comment-action button {
  border: none;
  background: transparent;
  font-size: 12px;
  font-weight: 600;
  color: #3a3737;
  text-transform: uppercase;
}
.comment-form input{
  height:50px;
  border-radius: 30px;
  padding-left: 20px;
  background: #f0f0f0;
  border: 1px solid #d3d3d3;
}
.add-comment {
  padding-bottom: 2rem;
}
.comment-form .add-comment-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  background: #880d4e;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.edit-comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.edit-comment p {
  margin: 0;
}
.edit-comment .ec-close {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.comment-info-box {
  padding:20px;
  padding-right: 0;
  border-bottom: 1px solid #880d4e;
}

/*Tagged*/
.tpvrb {
  margin-bottom: 15px;
}
.tpvrb label{
  margin-left: 20px;
  cursor: pointer;
}
.pvwdb {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.pvwdb img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.pvwdb p {
  margin: 0;
  margin: 0;
  line-height: 1.2;
}
.pvcib {
  margin-bottom: 1rem;
}
.pvcib input{
  border : 1px solid #880d4e;
}
.pvcib label {
  color:#880d4e!important;
}

/*Rating*/
.ratings__header {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d3d3d3;
}
.ratings__header span{
  position: relative;
  width: 60px;
  display: inline-block;
  text-align: left;
  background: #d3d3d3;
  color: #000;
  border-radius: 30px;
  padding: 5px 10px;
  padding-left: 15px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.selected_rating {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  font-size: 9px;
  height: 18px;
  width: 18px;
  line-height: 20px;
  right: 8px;
  top: 6px;
  text-align: center;
}
.fr__star {
  position: absolute;
  right: 8px;
  top: 9px;
}
.rlsection {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d3d3d3;
}
.rupb {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.user-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.rupb p {
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}
.rsbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  background-color: #880d4e;
  height: 60px;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
}
.rsbox p {
  margin: 0;
}
.rstars .star.filled {
  color:#ffffff;
}
.rl-details {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
}
.rl-details p {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px 0;
  margin-bottom: 0;
  border-bottom: 1px solid #d3d3d3;
  text-transform: uppercase;
}
.rating-section p {
  justify-content: space-between;
}
.rl-details .rating-icon {
  width:50px;
  height:50px;
}
.rl-details .rating-stars {
  margin-left: auto;
}
.rcbox {
  margin-top: 1rem;
}
.rcbox p {
  font-size: 14px;
  text-transform: none;
  border: none;
  margin: 0;
}

/*Post*/
.upload__image-wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: relative;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
}
.upload__image-wrapper .image-item {
  position: relative;
  padding:10px 0;
  background-color: #d3d3d3;
  cursor: pointer;
}
.upload__image-wrapper .image-item img {
  height: 100px;
  object-fit: cover;
}
.image-item__btn-wrapper {
  position: absolute;
  top: 0;
  right: 5px;
}
.image-item__btn-wrapper .img_delete_btn {
  background: red;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
}
.upload__btn {
  border:none;
  padding:10px;
  background-color: #d3d3d3;
}
.upload__btn img {
  width:100px;
  height: 100px;
}
.uploaded_image_box {
  margin-bottom: 1.5rem;
}
.uploaded_image_box .image-preview {
  background: #d3d3d3;
  padding: 50px 10px;
}
.uploaded_image_box .image-preview img {
  display: block;
  width: 400px;
  margin: auto;
}
.uploaded_image_box .image-preview video {
  display: block;
  width: 400px;
  margin: auto;
}
.upload__image-wrapper::-webkit-scrollbar {
  width: 12px;
}
.upload__image-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.upload__image-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}
.upload__image-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.upload__image-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
.caption_box textarea {
  border:1px solid #000;
  width:100%;
  padding:10px 15px;
}
.post_btn {
  border: none;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  height: 40px;
  width: 100%;
  border-radius: 5px;
}
.post-popup {
  display: flex!important;
  align-items: center;
  justify-content: center;
  position:relative;
}
.drawer .MuiPaper-root{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.drawer-close-btn {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  margin: 6px;
}

.post:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  min-height: 100px;
  border-radius: 0.5rem;
  background-color: #fff;
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.post:hover {
  border-color: #880d4e;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon .post-img-icon {
  width: 70px;
}
.post {
  fill: #880d4e;
}

.radio-label {
  color: #707070;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio-post-title {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

/*Menu*/
.manage-section {
  position:relative;
  min-height: 85vh;
}
.manage-section .add-data {
  position: fixed;
  border: none;
  background: #880d4e;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  bottom: 20px;
  left: 60%;
  transform: translateX(-50%);
}
.afobox {
  margin-bottom: 1rem;
}
.search-box {
  margin-bottom: 3rem;
  border: 2px solid #880d4e;
  border-radius: 5px;
  position: relative;
}
.search-box button {
  border: none;
  position: absolute;
  top: 6px;
  right: 10px;
  background: #000;
  color: #fff;
  border-radius: 4px;
}
.lsertbox h4 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 2rem;
}
.lser-box {
  background-color: #fff;
  padding: 0;
  padding-bottom: 1rem;
  border-radius: 0;
  border-bottom: 1px solid #d3d3d3;
}
.lser-header {
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}
.lser-ih {
  display: flex;
  align-items: center;
}
.upload_img {
  width:600px;
  height: 240px;
  object-fit: cover;
}
.image__upload_btn {
  position: absolute;
  bottom: 0;
  background: #8a8181;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
}
.lser-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}
.lser-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}
.lserv-btn-box {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.lser-body button{
  border:none;
}
.lser-name {
  margin-bottom: 10px !important;
}
.lserca {
  background-color: #68db98;
  color: #000;
  border-radius: 30px;
  text-align: center;
  min-width: 100px;
  width: fit-content;
  padding: 5px;
  margin-bottom: 10px !important;
}
.lsercu {
  margin: 0;
}
.slide-list {
  background: #d3d3d3;
  display: inline-block;
  width: 70px;
  text-align: center;
  border-radius: 30px;
  height: 30px;
  align-content: center;
  font-weight: 600;
  margin-right: 10px;
}
.ban-cat {
  color: grey;
  font-size: 12px;
  border-right: 1px solid;
  padding-right: 5px;
  padding-left: 5px;
}
.ban-cat:last-child{
  border:none;
}
.ml-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  height: 30px;
  margin-bottom: 15px;
  text-decoration: none;
  border-radius: 5px;
}
.lser-price {
  margin: 0;
  color: #008000;
  font-weight: 500;
}
.lser-price.discount-price {
  color: #ff0000;
  text-decoration: line-through;
}
.radio-box {
  display: flex;
  align-items: center;
}
.lserv-form-check {
  padding-left: 0!important;
}
.lserv-radio-label .lserv-radio-input {
  display: none;
}
.lserv-radio-label .lserv-radio-span {
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  text-align: center;
  background: #d3d3d3;
  color: #000;
  border-radius: 30px;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: 800;
  font-size: 13px;
  cursor: pointer;
}
.lserv-radio-label .lserv-radio-span em {
  display:none;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-size: 11px;
  padding-top: 3px;
  margin-right: 5px;
}
.lserv-radio-input:checked + span em {
  display: inline-block;
}
.status__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.toggle {
  position:relative;
  display: flex;
  align-items: center;
  background: none;
  margin-right: 40px;
  margin-top: -14px;
}
.toggle input[type="checkbox"] {
  opacity: 0 !important;
}
.toggle input[type="checkbox"] + label:before {
  -webkit-transition: background-color 0.4s ease;
  -moz-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  top: 0.25rem;
  left: 0;
  content: '';
  width: 2.25rem;
  height: 0.75rem;
  background-color: #767676;
  border-radius: 0.5rem;
}
.toggle input[type="checkbox"]:checked + label:before {
  background-color: #880d4e73;
}
.toggle input[type="checkbox"] + label:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  position: absolute;
  top: 0rem;
  left: -0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  content: '';
  font-size: 0.75em;
  background-color: #fff;
  border-radius: 500rem;
  border: 2px solid #767676;
}
.toggle input[type="checkbox"]:checked + label:after {
  transform: translateX(1.5rem);
  border: 2px solid #880d4e;
  background-color: #880d4e;
}
.coupon_code {
  width: fit-content;
  background: lightgrey;
  padding: 5px 10px;
  border: 1px dashed #000;
}
.shsnbox {
  display: flex;
  align-items: center;
}
.shsnbox div {
  min-width: 200px;
}
.rsfbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*Push Notification*/
.pntbox {
  background: #cac2bf;
  color: #000;
}
.pntbox p {
  margin: 0;
  padding:10px;
}
.tadb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  background: #000;
  color: #fff;
  padding: 10px;
  height: 50px;
  border-radius: 5px;
}
.pnedbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pnedbox .form-check-input[type=checkbox] {
  margin: 0;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  cursor: pointer;
  border: 2px solid #880d4e !important;
}
.pnexitbox {
  display: flex;
  align-items: center;
  justify-content: left;
}
.pnexitbox .pneximg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right:20px;
}
.pnexitbox p {
  margin-bottom: 0;
}
.attach-txt {
  text-align: center;
  color: #1769ff;
  cursor: pointer;
  text-transform: uppercase;
}
.remove-offer-txt {
  color: #880d4e;
  cursor: pointer;
  text-transform: uppercase; 
}

/*Time Popup*/
.time-box {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;
}
.timelabel {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width:140px;
}
.time-box input[type=checkbox]{
  border-radius: 2px !important;
  border: 2px solid #000;
  width: 18px;
  height: 18px;
  margin-top: 0;
}
.macbox {
  cursor: pointer;
  min-width: 100px;
  color:red;
}
.add-interval-btn {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  right: 5px;
}
.delete-interval-btn {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 15px;
  right: 5px;
}

/*Notifications*/
.noti-time {
  color: #880d4e;
  font-size: 14px!important;
  font-weight: 500;
}
.ntab-buttons .tb-btn {
  width: 100%;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 2px solid #898585;
  background: #d3d3d3;
  margin-right: 10px;
  border-radius: 2px;
}
.ntab-buttons .tb-btn.tb-active {
  background: #880d4e;
  color:#fff;
}

/*popup css*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  z-index: 1099;
  overflow: auto;
}
.popup-content {
  transition: transform 0.3s ease-out;
  display: inline-block;
  vertical-align: middle;
  width: 600px;
  margin: 30px auto;
}
.popup-close {
  font-size: 30px;
  font-weight: 600;
  margin-top: -10px;
  cursor: pointer;
}