.login-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}
form{
	font-family: 'Hero-R';
}
.login-form .login-title {
    display: block;
    font-weight: 800;
}
.message-box{
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
}
select{
    background: transparent;
    border: none;
}
:focus-visible {
    outline: none;
}
label {
	display:block;
	text-align:left;
}
.inputs input {
	display:inline-block;
    width: 40px;
    height: 40px;
    padding: 0;
	border: 2px solid #000000;
	color: #000000;
	margin-bottom:8px;
}

.inputs input:focus {
	color: #000000;
}
.countdown.resend-text {
    background: #000;
    color: #fff;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.resend-text{
	color: #000000;
	margin-bottom: 0.5rem;
	font-size: 11px;
    font-weight: 800;
}
.terms-block{
	color: #000000;
	font-size: 11px;
	font-weight: 800;
	margin-top: 26px;
}
.terms-block a {
    color: #000;
    font-size: 11px;
    text-decoration: underline;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}